var data = {
    "0": {
        "param_order": [
            "0", 
            "1", 
            "2", 
            "3", 
            "4", 
            "5", 
            "6", 
            "7", 
            "8", 
            "9", 
            "10", 
            "11", 
            "12", 
            "13", 
            "14", 
            "15", 
            "16", 
            "17", 
            "18", 
            "19", 
            "20"
        ], 
        "parameters": {
            "0": {
                "name": {
                    "label": "durum", 
                    "translation": {
                        "en": "status", 
                        "tr": "durum"
                    }, 
                    "value": "0"
                }, 
                "options": [
                    {
                        "label": "ödeme bekleniyor", 
                        "translation": {
                            "en": "payment waiting", 
                            "tr": "ödeme bekleniyor"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "ödeme onaylandı", 
                        "translation": {
                            "en": "payment approved", 
                            "tr": "ödeme onaylandı"
                        }, 
                        "value": "1"
                    }, 
                    {
                        "label": "fatura tamamlandı", 
                        "translation": {
                            "en": "bill completed", 
                            "tr": "fatura tamamlandı"
                        }, 
                        "value": "2"
                    }, 
                    {
                        "label": "ödeme iptal", 
                        "translation": {
                            "en": "payment cancel", 
                            "tr": "ödeme iptal"
                        }, 
                        "value": "3"
                    }, 
                    {
                        "label": "ödeme iade edildi", 
                        "translation": {
                            "en": "payment refunded", 
                            "tr": "ödeme iade edildi"
                        }, 
                        "value": "4"
                    }, 
                    {
                        "label": "fatura iptal edildi", 
                        "translation": {
                            "en": "bill cancelled", 
                            "tr": "fatura iptal edildi"
                        }, 
                        "value": "5"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "1": {
                "name": {
                    "label": "ödeme tipi", 
                    "translation": {
                        "en": "pay type", 
                        "tr": "ödeme tipi"
                    }, 
                    "value": "1"
                }, 
                "options": [
                    {
                        "label": "para ödeme", 
                        "translation": {
                            "en": "pay money", 
                            "tr": "para ödeme"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "hediye", 
                        "translation": {
                            "en": "gifr", 
                            "tr": "hediye"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "10": {
                "name": {
                    "label": "fatura iptal tarihi", 
                    "translation": {
                        "en": "bill cancellation date", 
                        "tr": "fatura iptal tarihi"
                    }, 
                    "value": "10"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }, 
            "11": {
                "name": {
                    "label": "ödeme bekleme kullanıcı", 
                    "translation": {
                        "en": "payment waiting user", 
                        "tr": "ödeme bekleme kullanıcı"
                    }, 
                    "value": "11"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "12": {
                "name": {
                    "label": "ödeme onaylayan kullanıcı", 
                    "translation": {
                        "en": "payment confirming user", 
                        "tr": "ödeme onaylayan kullanıcı"
                    }, 
                    "value": "12"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "13": {
                "name": {
                    "label": "fatura oluşturan kullanıcı", 
                    "translation": {
                        "en": "bill create user", 
                        "tr": "fatura oluşturan kullanıcı"
                    }, 
                    "value": "13"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "14": {
                "name": {
                    "label": "ödeme iptal kullanıcı", 
                    "translation": {
                        "en": "payment cancel user", 
                        "tr": "ödeme iptal kullanıcı"
                    }, 
                    "value": "14"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "15": {
                "name": {
                    "label": "ödeme iade kullanıcı", 
                    "translation": {
                        "en": "payment refund user", 
                        "tr": "ödeme iade kullanıcı"
                    }, 
                    "value": "15"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "16": {
                "name": {
                    "label": "fatura iptal kullanıcı", 
                    "translation": {
                        "en": "bill cancel user", 
                        "tr": "fatura iptal kullanıcı"
                    }, 
                    "value": "16"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "17": {
                "name": {
                    "label": "ödeme tarihi", 
                    "translation": {
                        "en": "payment date", 
                        "tr": "ödeme tarihi"
                    }, 
                    "value": "17"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }, 
            "18": {
                "name": {
                    "label": "mağaza client id", 
                    "translation": {
                        "en": "shop client id", 
                        "tr": "mağaza client id"
                    }, 
                    "value": "18"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "19": {
                "name": {
                    "label": "not", 
                    "translation": {
                        "en": "note", 
                        "tr": "not"
                    }, 
                    "value": "19"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "textarea"
            }, 
            "2": {
                "name": {
                    "label": "ödeme yolu", 
                    "translation": {
                        "en": "pay method", 
                        "tr": "ödeme yolu"
                    }, 
                    "value": "2"
                }, 
                "options": [
                    {
                        "label": "elden", 
                        "translation": {
                            "en": "hand payment", 
                            "tr": "elden"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "kredi kartı", 
                        "translation": {
                            "en": "credit card", 
                            "tr": "kredi kartı"
                        }, 
                        "value": "1"
                    }, 
                    {
                        "label": "hesaba para transferi", 
                        "translation": {
                            "en": "money transfer to account", 
                            "tr": "hesaba para transferi"
                        }, 
                        "value": "2"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "20": {
                "name": {
                    "label": "kdv oranı", 
                    "translation": {
                        "en": "vat ratio", 
                        "tr": "kdv oranı"
                    }, 
                    "value": "20"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "float"
            }, 
            "3": {
                "name": {
                    "label": "para birimi tipi", 
                    "translation": {
                        "en": "cur type", 
                        "tr": "para birimi tipi"
                    }, 
                    "value": "3"
                }, 
                "options": [
                    {
                        "label": "tl", 
                        "translation": {
                            "en": "tl", 
                            "tr": "tl"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "dolar", 
                        "translation": {
                            "en": "dollar", 
                            "tr": "dolar"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "4": {
                "name": {
                    "label": "para miktarı", 
                    "translation": {
                        "en": "cur amount", 
                        "tr": "para miktarı"
                    }, 
                    "value": "4"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "float"
            }, 
            "5": {
                "name": {
                    "label": "ödeme bekleme tarihi", 
                    "translation": {
                        "en": "payment waiting date", 
                        "tr": "ödeme bekleme tarihi"
                    }, 
                    "value": "5"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }, 
            "6": {
                "name": {
                    "label": "ödeme onaylama tarihi", 
                    "translation": {
                        "en": "payment approve date", 
                        "tr": "ödeme onaylama tarihi"
                    }, 
                    "value": "6"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }, 
            "7": {
                "name": {
                    "label": "fatura tarihi", 
                    "translation": {
                        "en": "bill date", 
                        "tr": "fatura tarihi"
                    }, 
                    "value": "7"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }, 
            "8": {
                "name": {
                    "label": "ödeme iptal tarihi", 
                    "translation": {
                        "en": "payment cancel date", 
                        "tr": "ödeme iptal tarihi"
                    }, 
                    "value": "8"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }, 
            "9": {
                "name": {
                    "label": "ödeme iade tarihi", 
                    "translation": {
                        "en": "payment refund date", 
                        "tr": "ödeme iade tarihi"
                    }, 
                    "value": "9"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }
        }
    }, 
    "about": [], 
    "bmi_gfr": "no", 
    "calculate_eval": "no", 
    "checked_on_open": "no", 
    "date_ability": "yes", 
    "date_ability_limit": "none", 
    "date_ability_type": "date", 
    "date_ability_user_selection": "no", 
    "document_upload": "no", 
    "ending_date_ability": "yes", 
    "ending_date_ability_limit": "none", 
    "ending_date_ability_type": "date", 
    "ending_date_ability_user_selection": "no", 
    "favorite_show_mode": "yes", 
    "language": {
        "label": "Türkçe", 
        "value": "tr"
    }, 
    "model": "wisdom_data", 
    "module": [], 
    "name": {
        "label": "wdm_name !!!", 
        "translation": {
            "en": "user shopping cart bundle product payment", 
            "tr": "kullanıcı alışveriş sepeti paket ürün ödeme"
        }, 
        "value": "ly + Created Layer Id !!!"
    }, 
    "owner": {
        "label": "katman", 
        "value": "layer"
    }, 
    "owner_type": "layer", 
    "param_group": {
        "param_order": [
            "0"
        ], 
        "parameters": {
            "0": {
                "label": "Param", 
                "value": "0"
            }
        }
    }, 
    "parent": "ly + Created Layer Id", 
    "record_count": "many_times", 
    "status": "published", 
    "style": {
        "caption": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 12
        }, 
        "value": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 10
        }
    }, 
    "value": "ly + Created Layer Id", 
    "version": "1.0"
};
export { data }
